import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 96, 100',
		'primary-dark': '0, 54, 58',
		'accent': '255, 171, 0',
		'accent-plus': '0, 0, 0',
	},
});
